<template>
  <div class="page-container">
    <LoadingIcon type="dark" />
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      LoadingIcon,
    },
    computed: {
      ...mapState('app', ['stripePlansIdMap', 'stripePricesIdMap']),
    },
    created() {

      const stripeSessionId = this.$route.query.stripeSessionId || this.$route.query.stripe_session_id || null

      if (this.$route.params.type === 'cancel') {
        return this.$router.replace('/account/plans')
      }

      this.$router.replace({ query: null })

      return this.$store.state.api.dispatch.get(`/stripe/session/${stripeSessionId}`)
        .then((response) => {

          const stripeSession = response.data

          if (
            !stripeSession.metadata.priceId
            || !this.stripePricesIdMap[stripeSession.metadata.priceId]
            || !this.stripePlansIdMap[this.stripePricesIdMap[stripeSession.metadata.priceId].product]
          ) {
            throw new Error(`Unknown Stripe price ID: ${stripeSession.metadata.priceId}`)
          }

          this.$router.replace('/account/plans')
            .then(() => {
              this.$store.dispatch('modals/OPEN_MODAL', {
                name: 'StripeCheckoutReturnModal',
                props: {
                  stripeSession,
                },
              })
            })

        })
        .catch((error) => {
          this.$store.dispatch('toast/CREATE_TOAST', {
            text: 'Your purchase was successful, but there was a problem retrieving info about your order.',
            type: 'error',
          })
          this.$router.replace('/dashboard')
        })

    },
  }

</script>

<style lang="stylus" scoped>

  .page-container
    @apply w-full
    @apply h-full

    @apply flex
    @apply items-center
    @apply justify-center

</style>
